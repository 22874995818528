export interface Business {
  name: string;
  status: "Currently Operating" | "Acquired";
  period: string;
  description: string;
  link?: string;
  metrics: string[];
}

export const businesses: Business[] = [
  {
    name: "NicoServe",
    status: "Currently Operating",
    period: "2025 - Present",
    description:
      "NicoServe delivers cost-effective, 24/7 cloud engineering solutions with certified AWS and Azure experts, allowing businesses to focus on their core operations while leveraging offshore talent to scale faster, minimize downtime, and optimize cloud performance—all supported by a customer-first approach",
    link: "https://nicoserve.com",
    metrics: ["Cloud Augmentation", "24/7 Support", "Global Coverage"]
  },
  {
    name: "Alertzy",
    status: "Currently Operating",
    period: "2025 - Present",
    description:
      "Alertzy redefines system monitoring with AI-powered insights and real-time solutions. Our platform enables enterprises to query performance metrics in plain language, anticipate issues with predictive analytics, and automate remediation workflows, ensuring seamless operations and minimal disruptions",
    link: "https://alertzy.com",
    metrics: ["AI-Powered", "Real-time Monitoring", "Predictive Analytics"]
  },
  {
    name: "Tricoda",
    status: "Acquired",
    period: "2019 - 2020",
    description:
      "Tricoda revolutionizes multi-cloud management through intelligent software solutions. Our platform seamlessly integrates AWS, Azure, and Google Cloud operations, providing unified monitoring, automated resource optimization, and centralized governance—enabling enterprises to efficiently manage complex cloud infrastructures while reducing operational overhead",
    metrics: ["Multi-cloud Integration", "Resource Optimization"]
  },
  {
    name: "Dediserve",
    status: "Acquired",
    period: "2009 - 2017",
    description:
      "Dediserve is your trusted partner for high-performance cloud hosting. With global data centers, scalable solutions, and 24/7 expert support, we ensure your business thrives in the cloud. Whether you're hosting applications, managing workloads, or scaling operations, Dediserve delivers the reliability and performance you need to succeed",
    metrics: ["Global Infrastructure", "Enterprise Hosting", "High Performance"]
  }
];
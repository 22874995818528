
import { motion } from "framer-motion";

export const HeroContent = () => {
  return (
    <div className="md:w-2/3 space-y-8">
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.7, delay: 0.3 }}
        className="space-y-7"
      >
        <motion.span 
          initial={{ opacity: 0, y: -10 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.4 }}
          className="inline-flex items-center px-4 py-2 text-sm font-semibold bg-violet-500/15 text-violet-300 rounded-full border border-violet-500/30 shadow-lg shadow-violet-500/10"
        >
          <svg className="w-4 h-4 mr-2 text-violet-400 animate-pulse" fill="currentColor" viewBox="0 0 20 20">
            <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
          </svg>
          <motion.span
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.6, duration: 0.5 }}
          >
            Enterprise Technology Leadership
          </motion.span>
        </motion.span>
        
        {/* Enhanced headline with better text gradient and animation */}
        <motion.h1 
          initial={{ opacity: 0, y: 30 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ 
            duration: 0.8, 
            delay: 0.4,
            ease: [0.22, 1, 0.36, 1]
          }}
          className="text-4xl md:text-6xl lg:text-7xl font-bold font-display tracking-tight leading-tight"
        >
          <motion.span 
            initial={{ backgroundPosition: "0% 50%" }}
            animate={{ backgroundPosition: ["0% 50%", "100% 50%", "0% 50%"] }}
            transition={{ duration: 15, repeat: Infinity, ease: "linear" }}
            className="inline-block bg-gradient-to-r from-violet-200 via-white to-violet-200 bg-[size:200%] text-gradient pb-2"
          >
            Transforming Businesses
          </motion.span>
          <br />
          <motion.span 
            initial={{ backgroundPosition: "100% 50%" }}
            animate={{ backgroundPosition: ["100% 50%", "0% 50%", "100% 50%"] }}
            transition={{ duration: 15, repeat: Infinity, ease: "linear" }}
            className="inline-block bg-gradient-to-r from-violet-300 via-blue-200 to-violet-300 bg-[size:200%] text-gradient pb-2"
          >
            Through Technology
          </motion.span>
        </motion.h1>

        {/* Enhanced description text with better spacing and animations */}
        <motion.div 
          initial={{ opacity: 0, y: 30 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.7, delay: 0.5 }}
          className="space-y-6 text-lg md:text-xl text-violet-100/90 max-w-2xl leading-relaxed font-light"
        >
          <p>
            Currently building{" "}
            <a href="https://nicoserve.com" className="text-violet-300 hover:text-violet-200 interactive-link transition-colors">
              NicoServe
            </a>{" "}
            and{" "}
            <a href="https://alertzy.com" className="text-violet-300 hover:text-violet-200 interactive-link transition-colors">
              Alertzy
            </a>
            , delivering enterprise cloud solutions and innovative monitoring tools.
          </p>

          <p>
            Previously founded and successfully exited Dediserve and Tricoda, demonstrating a track record of building and scaling technology ventures.
          </p>
        </motion.div>
      </motion.div>
      
      {/* Enhanced CTA button with better hover effects */}
      <motion.div 
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.7, delay: 0.6 }}
        className="flex flex-wrap gap-4 pt-4"
      >
        <motion.a
          href="#contact"
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.98 }}
          className="group px-8 py-4 bg-gradient-to-r from-violet-600 to-violet-700 hover:from-violet-600 hover:to-violet-800 text-white rounded-xl transition-all text-lg font-semibold shadow-xl shadow-violet-500/10 hover:shadow-violet-500/30 flex items-center gap-2 relative overflow-hidden"
        >
          <span className="absolute inset-0 w-full h-full bg-gradient-to-r from-transparent via-white/10 to-transparent -translate-x-full group-hover:translate-x-full transition-transform duration-1000 ease-in-out"></span>
          <svg className="w-5 h-5 group-hover:scale-110 transition-transform duration-300" fill="none" stroke="currentColor" viewBox="0 0 24 24">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
          </svg>
          Contact Me
        </motion.a>
      </motion.div>
    </div>
  );
};

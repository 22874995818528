
import { motion } from "framer-motion";
import { useState, useEffect } from "react";
import { Background } from "./hero/Background";
import { Particles } from "./hero/Particles";
import { GradientBlobs } from "./hero/GradientBlobs";
import { ProfileImage } from "./hero/ProfileImage";
import { HeroContent } from "./hero/HeroContent";

export const Hero = () => {
  const [scrollOpacity, setScrollOpacity] = useState(1);
  
  // Handle scroll indicator opacity based on scroll position
  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const maxScroll = 300; // At this scroll position, the indicator will be fully transparent
      const opacity = Math.max(0, 1 - (scrollPosition / maxScroll));
      setScrollOpacity(opacity);
    };
    
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <section className="relative min-h-[90vh] flex items-center justify-center px-4 py-16 bg-gradient-to-br from-violet-950/90 via-slate-900/90 to-slate-950/90 overflow-hidden">
      {/* Background components */}
      <Background />
      
      {/* Particles component */}
      <Particles />
      
      {/* Gradient blobs component */}
      <GradientBlobs />
      
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.7 }}
        className="max-w-7xl mx-auto relative z-10 w-full"
      >
        <div className="flex flex-col md:flex-row items-center gap-12 md:gap-16">
          {/* Profile image component */}
          <ProfileImage />
          
          {/* Content component */}
          <HeroContent />
        </div>
      </motion.div>
    </section>
  );
};

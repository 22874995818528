
import { motion } from "framer-motion";

export const Particles = () => {
  return (
    <div className="absolute inset-0 overflow-hidden">
      {[...Array(35)].map((_, i) => (
        <motion.div
          key={i}
          initial={{ 
            top: `${Math.random() * 100}%`, 
            left: `${Math.random() * 100}%`,
            scale: Math.random() * 0.5 + 0.5,
            opacity: Math.random() * 0.5 + 0.2
          }}
          animate={{ 
            y: [-15, 15, -15],
            opacity: [0.2, 0.5, 0.2]
          }}
          transition={{ 
            repeat: Infinity, 
            duration: 5 + Math.random() * 10,
            delay: Math.random() * 5,
            ease: "easeInOut" 
          }}
          className={`absolute rounded-full ${
            i % 3 === 0 
              ? 'w-2.5 h-2.5 bg-violet-400/40' 
              : i % 3 === 1 
                ? 'w-2 h-2 bg-indigo-400/35' 
                : 'w-1.5 h-1.5 bg-blue-400/30'
          }`}
        />
      ))}
    </div>
  );
};

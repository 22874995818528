
import { motion } from "framer-motion";

export const ProfileImage = () => {
  return (
    <motion.div
      initial={{ scale: 0.5, opacity: 0 }}
      animate={{ scale: 1, opacity: 1 }}
      transition={{ 
        duration: 0.7, 
        delay: 0.2,
        ease: [0.22, 1, 0.36, 1] 
      }}
      className="md:w-1/3 lg:w-1/4"
    >
      <motion.div 
        className="relative group"
        whileHover={{ scale: 1.05 }}
        transition={{ duration: 0.5 }}
      >
        <div className="absolute -inset-1 bg-gradient-to-r from-violet-600 to-blue-600 rounded-2xl blur opacity-70 group-hover:opacity-85 transition duration-1000 group-hover:duration-200 animate-pulse-slow"></div>
        <div className="relative">
          <img
            src="/lovable-uploads/bacde291-7449-46e5-9f42-ab460b002197.png"
            alt="Aidan Mc Carron"
            className="rounded-2xl relative z-10 object-cover w-full aspect-[3/4] shadow-2xl transform transition duration-500 group-hover:shadow-violet-500/25"
          />
          {/* Enhanced gradient overlay on image */}
          <div className="absolute inset-0 rounded-2xl bg-gradient-to-t from-black/50 via-black/20 to-transparent z-20"></div>
          
          {/* Animated border highlight */}
          <motion.div 
            className="absolute inset-0 rounded-2xl border-2 border-violet-400/0 z-30"
            whileHover={{ borderColor: "rgba(167, 139, 250, 0.5)" }}
            transition={{ duration: 0.3 }}
          />
        </div>
      </motion.div>
    </motion.div>
  );
};


import { motion } from "framer-motion";

export const GradientBlobs = () => {
  return (
    <>
      <motion.div 
        animate={{ 
          filter: ["blur(70px)", "blur(90px)", "blur(70px)"],
          scale: [1, 1.1, 1]
        }}
        transition={{ 
          repeat: Infinity, 
          duration: 8,
          ease: "easeInOut" 
        }}
        className="absolute top-0 left-0 w-[600px] h-[600px] bg-violet-600/10 rounded-full mix-blend-multiply filter blur-3xl opacity-30"
      />
      <motion.div 
        animate={{ 
          filter: ["blur(70px)", "blur(90px)", "blur(70px)"],
          scale: [1, 1.2, 1]
        }}
        transition={{ 
          repeat: Infinity, 
          duration: 12,
          ease: "easeInOut",
          delay: 2 
        }}
        className="absolute top-0 right-0 w-[500px] h-[500px] bg-blue-600/10 rounded-full mix-blend-multiply filter blur-3xl opacity-30"
      />
      <motion.div 
        animate={{ 
          filter: ["blur(70px)", "blur(100px)", "blur(70px)"],
          scale: [1, 1.15, 1]
        }}
        transition={{ 
          repeat: Infinity, 
          duration: 10,
          ease: "easeInOut",
          delay: 1 
        }}
        className="absolute -bottom-32 left-20 w-[700px] h-[700px] bg-indigo-600/10 rounded-full mix-blend-multiply filter blur-3xl opacity-30"
      />
    </>
  );
};

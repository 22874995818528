
import { motion } from "framer-motion";
import { Cloud, Server, Cog, Lock } from "lucide-react";

const services = [{
  icon: Cloud,
  title: "Enterprise Cloud Solutions",
  description: "Scalable AWS and Azure infrastructure designed for high-availability and disaster recovery, ensuring your business stays operational."
}, {
  icon: Server,
  title: "Resource Augmentation",
  description: "Enterprise-grade talent solutions providing specialized expertise and scalable teams for mission-critical projects."
}, {
  icon: Cog,
  title: "24/7 Enterprise Support",
  description: "Dedicated support teams with guaranteed response times and comprehensive SLAs for mission-critical systems."
}, {
  icon: Lock,
  title: "Managed IT Services",
  description: "Comprehensive IT management solutions including monitoring, maintenance, and proactive support for enterprise systems."
}];

export const Services = () => {
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.2
      }
    }
  };

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: { 
      y: 0, 
      opacity: 1,
      transition: { 
        duration: 0.5,
        ease: [0.22, 1, 0.36, 1]
      }
    }
  };

  return (
    <section id="services" className="py-32 px-4 relative overflow-hidden bg-gradient-to-b from-violet-950 via-slate-950 to-black">
      {/* Enhanced background elements */}
      <div className="absolute inset-0 z-0">
        <div className="absolute inset-0 opacity-20 transition-opacity duration-1000" style={{
          backgroundImage: "url('/grid.svg')",
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          filter: 'saturate(1.4) brightness(0.5)'
        }} />
        <div className="absolute inset-0 bg-gradient-to-b from-violet-950/90 via-slate-950/95 to-black" />
      </div>
      
      {/* Animated gradient blobs */}
      <div className="absolute top-1/4 -left-64 w-[500px] h-[500px] bg-violet-600/10 rounded-full mix-blend-multiply filter blur-3xl opacity-20 animate-blob"></div>
      <div className="absolute bottom-1/4 -right-64 w-[600px] h-[600px] bg-indigo-600/10 rounded-full mix-blend-multiply filter blur-3xl opacity-20 animate-blob-delay-2"></div>
      
      {/* Enhanced grid overlay */}
      <div className="absolute inset-0 bg-[url('/grid.svg')] bg-center [mask-image:linear-gradient(180deg,white,rgba(255,255,255,0))] opacity-15" />
      
      {/* Enhanced floating particles effect */}
      <div className="absolute inset-0 overflow-hidden">
        {[...Array(30)].map((_, i) => (
          <div
            key={i}
            className={`absolute ${i % 3 === 0 ? 'w-1.5 h-1.5 bg-violet-400/30' : 'w-1 h-1 bg-indigo-400/20'} rounded-full`}
            style={{
              top: `${Math.random() * 100}%`,
              left: `${Math.random() * 100}%`,
              animation: `float ${5 + Math.random() * 15}s linear infinite`,
              animationDelay: `${-Math.random() * 10}s`
            }}
          />
        ))}
      </div>
      
      <div className="max-w-7xl mx-auto relative z-10">
        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, margin: "-100px" }}
          variants={containerVariants}
          className="text-center mb-20"
        >
          <motion.span 
            variants={itemVariants}
            className="inline-flex items-center px-4 py-1.5 text-sm font-medium bg-gradient-to-r from-violet-500/20 to-blue-500/20 text-violet-300 rounded-full mb-6 border border-violet-500/20"
          >
            <span className="w-2 h-2 rounded-full bg-violet-400 mr-2 animate-pulse" />
            Enterprise Solutions
          </motion.span>
          
          <motion.h2 
            variants={itemVariants}
            className="text-5xl md:text-6xl font-bold font-display tracking-tight mb-6 bg-clip-text text-transparent bg-gradient-to-r from-violet-200 via-white to-violet-200"
          >
            Elevate Your Enterprise
          </motion.h2>
          
          <motion.p 
            variants={itemVariants}
            className="mt-4 text-xl text-gray-300/90 max-w-3xl mx-auto leading-relaxed font-light"
          >
            Delivering enterprise-grade solutions that transform businesses through innovative technology and strategic expertise - Speak to me about any of the following.
          </motion.p>
        </motion.div>

        <motion.div 
          variants={containerVariants}
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, margin: "-100px" }}
          className="grid md:grid-cols-2 lg:grid-cols-4 gap-6 lg:gap-8"
        >
          {services.map((service, index) => (
            <motion.div
              key={index}
              variants={itemVariants}
              className="group relative"
            >
              <div className="absolute inset-0 bg-gradient-to-br from-violet-500/10 to-blue-500/10 rounded-2xl transform transition-transform duration-500 group-hover:scale-105 opacity-0 group-hover:opacity-100" />
              <div className="relative p-8 rounded-2xl enhanced-glass group-hover:border-violet-500/30 h-full flex flex-col">
                <div className="w-16 h-16 mb-8 relative">
                  <div className="absolute inset-0 bg-gradient-to-br from-violet-500/20 to-blue-500/20 rounded-2xl transform rotate-6 transition-transform duration-300 group-hover:rotate-12" />
                  <div className="absolute inset-0 bg-[#2A303F] rounded-2xl flex items-center justify-center">
                    <service.icon className="w-8 h-8 text-violet-300 group-hover:text-violet-200 transition-colors duration-300" />
                  </div>
                </div>
                <h3 className="text-2xl font-bold font-display text-white mb-4 group-hover:text-violet-200 transition-colors">
                  {service.title}
                </h3>
                <p className="text-gray-300/90 text-lg leading-relaxed font-light mt-auto">
                  {service.description}
                </p>
              </div>
            </motion.div>
          ))}
        </motion.div>
      </div>
    </section>
  );
};


import { motion } from "framer-motion";
import { Mail, Linkedin, X } from "lucide-react";

export const SocialLinks = () => {
  const container = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        staggerChildren: 0.2
      }
    }
  };

  const item = {
    hidden: { scale: 0.8, opacity: 0 },
    show: { scale: 1, opacity: 1 }
  };

  return (
    <motion.div 
      variants={container}
      initial="hidden"
      whileInView="show"
      viewport={{ once: true }}
      className="flex gap-6"
    >
      <motion.a
        variants={item}
        whileHover={{ scale: 1.1 }}
        whileTap={{ scale: 0.95 }}
        href="mailto:webmaster@aidanmccarron.com"
        className="p-5 rounded-full bg-violet-500/10 hover:bg-violet-500/30 transition-colors group backdrop-blur-sm border border-violet-500/20 hover:border-violet-500/40 hover:shadow-lg hover:shadow-violet-500/10 relative"
        aria-label="Email"
      >
        <div className="absolute inset-0 rounded-full bg-violet-500/5 animate-pulse opacity-0 group-hover:opacity-100"></div>
        <Mail className="w-8 h-8 text-violet-200 group-hover:scale-110 transition-transform" />
      </motion.a>
      
      <motion.a
        variants={item}
        whileHover={{ scale: 1.1 }}
        whileTap={{ scale: 0.95 }}
        href="https://linkedin.com/in/aidanmccarron"
        target="_blank"
        rel="noopener noreferrer"
        className="p-5 rounded-full bg-violet-500/10 hover:bg-violet-500/30 transition-colors group backdrop-blur-sm border border-violet-500/20 hover:border-violet-500/40 hover:shadow-lg hover:shadow-violet-500/10 relative"
        aria-label="LinkedIn"
      >
        <div className="absolute inset-0 rounded-full bg-violet-500/5 animate-pulse opacity-0 group-hover:opacity-100"></div>
        <Linkedin className="w-8 h-8 text-violet-200 group-hover:scale-110 transition-transform" />
      </motion.a>
      
      <motion.a
        variants={item}
        whileHover={{ scale: 1.1 }}
        whileTap={{ scale: 0.95 }}
        href="https://x.com/aidanmcc"
        target="_blank"
        rel="noopener noreferrer"
        className="p-5 rounded-full bg-violet-500/10 hover:bg-violet-500/30 transition-colors group backdrop-blur-sm border border-violet-500/20 hover:border-violet-500/40 hover:shadow-lg hover:shadow-violet-500/10 relative"
        aria-label="X (Twitter)"
      >
        <div className="absolute inset-0 rounded-full bg-violet-500/5 animate-pulse opacity-0 group-hover:opacity-100"></div>
        <X className="w-8 h-8 text-violet-200 group-hover:scale-110 transition-transform" />
      </motion.a>
    </motion.div>
  );
};

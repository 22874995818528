
import { motion } from "framer-motion";

export const Background = () => {
  return (
    <>
      {/* Enhanced animated background with parallax effect */}
      <div className="absolute inset-0 z-0">
        <div 
          className="absolute inset-0 opacity-30 transition-opacity duration-1000"
          style={{
            backgroundImage: "url('/server-farm.jpg')",
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            filter: 'saturate(1.5) brightness(0.65)'
          }}
        />
        <div className="absolute inset-0 bg-gradient-to-b from-violet-950/90 via-slate-900/95 to-slate-950/95" />
      </div>
      
      {/* Enhanced 3D grid overlay with parallax effect */}
      <motion.div 
        initial={{ y: 0 }}
        animate={{ y: [-10, 0, -10] }}
        transition={{ repeat: Infinity, duration: 10, ease: "easeInOut" }}
        className="absolute inset-0 bg-[url('/grid.svg')] bg-center [mask-image:linear-gradient(180deg,white,rgba(255,255,255,0))] opacity-25"
      />
    </>
  );
};

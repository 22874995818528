
import { motion } from "framer-motion";
import { businesses } from "@/data/businesses";
import { BusinessCard } from "./BusinessCard";

export const Experience = () => {
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.2
      }
    }
  };

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: { 
      y: 0, 
      opacity: 1,
      transition: { 
        duration: 0.5,
        ease: [0.22, 1, 0.36, 1]
      }
    }
  };

  return (
    <section 
      id="experience" 
      className="py-32 px-4 bg-slate-900 relative overflow-hidden"
      aria-labelledby="experience-title"
    >
      {/* Simple solid background */}
      
      <div className="max-w-6xl mx-auto relative z-10">
        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, margin: "-100px" }}
          variants={containerVariants}
          className="text-center mb-20"
        >
          <motion.span 
            variants={itemVariants}
            className="inline-block px-4 py-1.5 text-sm font-medium bg-violet-500/20 text-violet-300 rounded-full mb-8 border border-violet-500/20 shadow-sm shadow-violet-500/5"
          >
            Enterprise Solutions Portfolio
          </motion.span>
          
          <motion.h2 
            variants={itemVariants}
            id="experience-title"
            className="text-5xl md:text-6xl font-bold font-display tracking-tight text-white mb-8 relative z-20 pb-2"
          >
            <span className="relative inline-block">
              Strategic Business Ventures
              <motion.span 
                className="absolute -bottom-2 left-0 w-full h-1 bg-violet-500"
                initial={{ width: 0 }}
                whileInView={{ width: "100%" }}
                transition={{ delay: 0.5, duration: 0.8 }}
                viewport={{ once: true }}
              />
            </span>
          </motion.h2>
          
          <motion.p 
            variants={itemVariants}
            className="text-slate-300 max-w-2xl mx-auto text-lg leading-relaxed"
          >
            Track record of building and scaling enterprise-grade solutions that transform business operations
          </motion.p>
        </motion.div>

        <div className="grid md:grid-cols-2 gap-8 mb-20">
          {businesses.map((business, index) => (
            <BusinessCard key={business.name} business={business} index={index} />
          ))}
        </div>

        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          viewport={{ once: true }}
          className="bg-slate-800/80 p-12 rounded-2xl text-center max-w-3xl mx-auto"
        >
          <h3 className="text-3xl font-semibold font-display text-white mb-6">
            Looking to Connect?
          </h3>
          <p className="text-slate-300 leading-relaxed text-lg mb-8">
            Actively seeking conversations with founders and innovators in the cloud infrastructure space. If you're building something exciting and looking for investment or strategic partnerships, let's connect.
          </p>
          <a 
            href="mailto:webmaster@aidanmccarron.com" 
            className="group inline-flex items-center px-8 py-4 bg-violet-600 hover:bg-violet-700 text-white rounded-xl transition-all duration-300 font-medium text-lg"
          >
            Start a Conversation
          </a>
        </motion.div>
      </div>
    </section>
  );
};


import { motion } from "framer-motion";
import { Business } from "@/data/businesses";
import { 
  Calendar, 
  ExternalLink, 
  Briefcase, 
  CheckCircle2, 
  Clock, 
  Cloud, 
  Monitor, 
  Globe, 
  Rocket, 
  Shield, 
  Cpu, 
  BarChart 
} from "lucide-react";

interface BusinessCardProps {
  business: Business;
  index: number;
}

export const BusinessCard = ({ business, index }: BusinessCardProps) => {
  // Deterministic random pattern for each card based on business name
  const getPatternSeed = (name: string) => {
    let hash = 0;
    for (let i = 0; i < name.length; i++) {
      hash = name.charCodeAt(i) + ((hash << 5) - hash);
    }
    return Math.abs(hash) % 5 + 1; // 1-5 range
  };

  const patternNumber = getPatternSeed(business.name);

  // Pattern styles based on business
  const patternStyles = {
    backgroundImage: `url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%239C92AC' fill-opacity='0.2' fill-rule='evenodd'%3E%3Ccircle cx='3' cy='3' r='${patternNumber}'/%3E%3C/g%3E%3C/svg%3E")`,
    backgroundSize: `${patternNumber * 10}px ${patternNumber * 10}px`
  };

  // Determine icon based on business type - using first metric as category
  const getBusinessIcon = () => {
    const category = business.metrics[0]?.toLowerCase() || '';
    
    if (category.includes('cloud')) return <Cloud className="text-violet-400" size={18} />;
    if (category.includes('monitor')) return <Monitor className="text-blue-400" size={18} />;
    return <Briefcase className="text-violet-400" size={18} />;
  };

  // Get appropriate icon for each metric
  const getMetricIcon = (metric: string) => {
    const metricLower = metric.toLowerCase();
    
    if (metricLower.includes('cloud')) return <Cloud size={14} />;
    if (metricLower.includes('monitor')) return <Monitor size={14} />;
    if (metricLower.includes('global') || metricLower.includes('coverage')) return <Globe size={14} />;
    if (metricLower.includes('support') || metricLower.includes('24/7')) return <CheckCircle2 size={14} />;
    if (metricLower.includes('ai') || metricLower.includes('powered')) return <Cpu size={14} />;
    if (metricLower.includes('real-time')) return <Clock size={14} />;
    if (metricLower.includes('analytics') || metricLower.includes('predictive')) return <BarChart size={14} />;
    if (metricLower.includes('integration') || metricLower.includes('multi-cloud')) return <Rocket size={14} />;
    if (metricLower.includes('optimiz')) return <Shield size={14} />;
    if (metricLower.includes('enterprise') || metricLower.includes('infrastructure')) return <Briefcase size={14} />;
    if (metricLower.includes('performance')) return <BarChart size={14} />;
    
    // Default icon if no match
    return <Briefcase size={14} />;
  };

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      whileInView={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5, delay: index * 0.1 }}
      viewport={{ once: true }}
      className={`rounded-2xl group transition-all duration-500 hover:shadow-xl ${
        business.link ? 'cursor-pointer' : ''
      }`}
      onClick={() => business.link && window.open(business.link, '_blank')}
      role="article"
      aria-label={`${business.name} - ${business.status}`}
    >
      {/* Card content */}
      <motion.div 
        className="rounded-2xl p-8 bg-slate-800/80 group-hover:bg-slate-800/95 relative overflow-hidden transition-colors duration-300"
      >
        {/* Subtle card pattern overlay */}
        <div className="absolute inset-0 opacity-10 pointer-events-none" style={patternStyles} />
        
        <div className="flex flex-col space-y-6 relative z-10">
          <div className="flex justify-between items-start">
            <div>
              <div className="flex items-center gap-3 mb-3">
                <h3 className="text-2xl font-bold font-display text-white transition-colors duration-300">
                  {business.name}
                </h3>
                {business.link && (
                  <ExternalLink className="w-5 h-5 text-violet-400 opacity-0 group-hover:opacity-100 transition-opacity" />
                )}
              </div>
              <div className="flex items-center gap-2">
                <span className={`text-sm flex items-center gap-1 ${
                  business.status === "Currently Operating" 
                    ? "text-emerald-400 bg-emerald-400/10" 
                    : "text-blue-400 bg-blue-400/10"
                } px-3 py-1 rounded-full inline-block border border-current/20`}>
                  {business.status === "Currently Operating" ? (
                    <>
                      <span className="relative flex h-2 w-2 mr-1">
                        <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-emerald-400 opacity-75"></span>
                        <span className="relative inline-flex rounded-full h-2 w-2 bg-emerald-500"></span>
                      </span>
                      {business.status}
                    </>
                  ) : (
                    <>
                      <Clock size={14} className="mr-1" />
                      {business.status}
                    </>
                  )}
                </span>
              </div>
            </div>
            <span className="text-sm flex items-center gap-1 text-blue-300/80 bg-blue-500/10 px-4 py-2 rounded-full border border-blue-500/30 shadow-sm">
              <Calendar size={14} className="mr-1" />
              {business.period}
            </span>
          </div>
          
          <p className="text-slate-300 leading-relaxed text-lg">{business.description}</p>
          
          <div className="flex flex-wrap gap-3 mt-4" role="list" aria-label="Business metrics">
            {business.metrics.map((metric, idx) => (
              <motion.span 
                key={idx}
                initial={{ scale: 1 }}
                whileHover={{ scale: 1.05 }}
                className="text-sm flex items-center gap-1 text-blue-200 bg-blue-500/5 px-3 py-1.5 rounded-full border border-blue-500/20 hover:bg-blue-500/10 transition-colors"
                role="listitem"
              >
                {getMetricIcon(metric)}
                <span className="ml-1">{metric}</span>
              </motion.span>
            ))}
          </div>
        </div>
      </motion.div>
    </motion.div>
  );
};

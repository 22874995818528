
import { motion } from "framer-motion";
import { Mail, MapPin } from "lucide-react";
import { SocialLinks } from "./SocialLinks";

export const Contact = () => {
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.2
      }
    }
  };

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: { 
      y: 0, 
      opacity: 1,
      transition: { 
        duration: 0.5,
        ease: [0.22, 1, 0.36, 1]
      }
    }
  };

  return (
    <section id="contact" className="py-24 px-4 bg-gradient-to-br from-indigo-950 via-slate-900 to-slate-950 relative overflow-hidden">
      {/* Enhanced grid background with animated particles */}
      <div className="absolute inset-0 bg-[url('/grid.svg')] bg-center [mask-image:linear-gradient(180deg,white,rgba(255,255,255,0))] opacity-20" />
      
      {/* Animated gradient orbs with enhanced animations */}
      <div className="absolute top-0 -left-4 w-96 h-96 bg-blue-600/20 rounded-full mix-blend-multiply filter blur-3xl opacity-20 animate-blob" />
      <div className="absolute top-0 -right-4 w-96 h-96 bg-indigo-600/20 rounded-full mix-blend-multiply filter blur-3xl opacity-20 animate-blob-delay-2" />
      <div className="absolute bottom-0 left-1/4 w-96 h-96 bg-violet-600/20 rounded-full mix-blend-multiply filter blur-3xl opacity-20 animate-blob-delay-4" />
      
      {/* Enhanced floating particles */}
      <div className="absolute inset-0 overflow-hidden">
        {[...Array(20)].map((_, i) => (
          <div
            key={i}
            className={`absolute ${i % 3 === 0 ? 'w-1.5 h-1.5 bg-blue-400/30' : 'w-1 h-1 bg-violet-400/20'} rounded-full`}
            style={{
              top: `${Math.random() * 100}%`,
              left: `${Math.random() * 100}%`,
              animation: `float ${5 + Math.random() * 10}s linear infinite`,
              animationDelay: `${-Math.random() * 5}s`
            }}
          />
        ))}
      </div>
      
      <div className="max-w-6xl mx-auto grid md:grid-cols-2 gap-12 items-center relative">
        <motion.div 
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          variants={containerVariants}
          className="text-left"
        >
          <motion.span 
            variants={itemVariants}
            className="inline-block px-4 py-1.5 text-sm font-medium bg-blue-500/20 text-blue-200 rounded-full mb-6 backdrop-blur-sm border border-blue-500/30 shadow-sm shadow-blue-500/5"
          >
            Let's Connect
          </motion.span>
          
          <motion.h2 
            variants={itemVariants}
            className="text-4xl md:text-5xl font-bold font-display mb-6 tracking-tight bg-gradient-to-r from-blue-200 via-white to-indigo-200 text-gradient"
          >
            Transform Your Enterprise Infrastructure
          </motion.h2>
          
          <motion.p 
            variants={itemVariants}
            className="text-blue-100/90 mb-8 text-lg leading-relaxed backdrop-blur-sm max-w-2xl"
          >
            Through Alertzy and NicoServe, I specialize in helping enterprises achieve digital transformation, operational efficiency, and a competitive edge.
          </motion.p>

          <motion.div 
            variants={itemVariants}
            className="mb-8"
          >
            <span className="text-2xl font-bold font-display bg-gradient-to-r from-blue-300 to-indigo-300 text-gradient">
              Let's Connect
            </span>
          </motion.div>
          
          <SocialLinks />
        </motion.div>

        <motion.div 
          initial={{ opacity: 0, x: 20 }}
          whileInView={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.5 }}
          viewport={{ once: true }}
          className="enhanced-glass rounded-xl p-8 border border-blue-500/30 backdrop-blur-md space-y-8 hover:shadow-xl hover:shadow-blue-500/5 transition-all duration-500"
        >
          <div className="flex items-center space-x-4">
            <div className="p-3 rounded-full bg-blue-500/20 border border-blue-500/30 shadow-inner">
              <Mail className="w-6 h-6 text-blue-200" />
            </div>
            <div>
              <h3 className="text-lg font-semibold text-blue-200">Email</h3>
              <a href="mailto:webmaster@aidanmccarron.com" className="text-blue-100/80 hover:text-blue-100 interactive-link">
                webmaster@aidanmccarron.com
              </a>
            </div>
          </div>

          <div className="flex items-center space-x-4">
            <div className="p-3 rounded-full bg-blue-500/20 border border-blue-500/30 shadow-inner">
              <MapPin className="w-6 h-6 text-blue-200" />
            </div>
            <div>
              <h3 className="text-lg font-semibold text-blue-200">Location</h3>
              <p className="text-blue-100/80">Maynooth Business Campus, Maynooth, Kildare, Ireland</p>
            </div>
          </div>
          
          {/* Added subtle design element */}
          <div className="h-px w-full bg-gradient-to-r from-transparent via-blue-500/20 to-transparent my-4"></div>
          
          {/* Added contact info card */}
          <div className="bg-blue-500/5 p-4 rounded-lg border border-blue-500/10">
            <p className="text-blue-100/90 text-sm">
              For enterprise inquiries or partnership opportunities, please reach out via email or LinkedIn. I typically respond within 24 hours.
            </p>
          </div>
        </motion.div>
      </div>
    </section>
  );
};
